<style>
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 115vh;
  max-width: 100%;
  position: relative;
}
.v-select__selection--comma {
  margin: 7px 0px 7px 9px !important;
  font-weight:600;
}
.v-toolbar__title {
    white-space: normal !important;
}
</style>
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-app-bar
          dark
          color="#2C1963"
          app
          :height="$vuetify.breakpoint.smAndUp ? '100px' : '198px'"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="3"
                class="px-0 text-center"
                style="display: flex;"
           >
                <v-img
                  :max-width="$vuetify.breakpoint.smAndUp ? '75' : '40'"
                  src="@/assets/publicDonationPage.png"
                  :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : 'mr-2 mt-2'"
                />
                <v-toolbar-title style="font-family: LubalGraph Bd BT">
                  <div class="appBarTitle">Welcome to the WizFit Challenge</div>
                </v-toolbar-title>
              </v-col>
              <div style="margin-top: 12px" v-if="this.camapignId==207">
                Select Language
                <v-select
                  :items="languageList"
                  item-text="name"
                  item-value="value"
                  v-model="lang"
                  style="border-radius: 6px;"
                  @change="getCampaignDetails"
                ></v-select>
              </div>
<!--  background-color: #ffba00 .v-input__icon.v-input__icon--append i::before {
  color: #2c1963;
}-->
              <v-col
                v-if="!isLoggedIn"
                cols="12"
                sm="6"
                :class="
                  $vuetify.breakpoint.smAndUp
                    ? 'py-5 pl-sm-16 pr-3'
                    : 'pl-0 py-0 pb-4'
                "
                style="position: relative; width: 100%; display: flex"
              >
                <div style="position: relative; width: 100%; display: flex">
                  <v-text-field
                    v-model="searchQuery"
                    :loading="searchLoading"
                    outlined
                    single-line
                    solo-inverted
                    dense
                    class="mr-2"
                    :height="$vuetify.breakpoint.smAndUp ? 55 : 45"
                    label="Search for School, Teacher, Student"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                  >
                    <template v-slot:label>
                      <div
                        :style="
                          $vuetify.breakpoint.smAndUp
                            ? 'color: white; font-size: 20px; margin-left: 5px'
                            : ''
                        "
                      >
                        Search for School, Teacher, Student
                      </div>
                    </template>
                  </v-text-field>
                  <v-expand-transition>
                    <v-card
                      v-show="searchQuery"
                      light
                      style="
                        position: absolute;
                        overflow-y: auto;
                        max-height: 340px;
                      "
                      class="card-searchResult mt-16"
                      min-width="90%"
                      elevation="20"
                    >
                      <v-list class="pa-0" three-line>
                        <template v-for="(item, index) in searchList">
                          <v-list-item
                            @click="searchItemClickHandler(item)"
                            :key="index"
                          >
                            <v-list-item-avatar class="align-self-center">
                              <v-img
                                v-if="item.profile_pic !== null"
                                :src="item.profile_pic"
                              ></v-img>
                              <v-avatar v-else color="red" size="40">
                                <span class="white--text text-h6">{{
                                  getInitials(item.name)
                                }}</span>
                              </v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="searchList-title">{{
                                  item.name
                                }}</span>
                                <v-chip
                                  small
                                  class="mx-2 mt-n1"
                                  :class="item.user_type"
                                  style="font-size: 17px"
                                >
                                  {{ item.user_type }}
                                </v-chip>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'student'"
                                v-html="
                                  `${item.grade_teacher} (Grade ${item.grade_title})`
                                "
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'student'"
                                v-html="item.school_name"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'teacher'"
                                v-html="
                                  `Grade ${item.grade_title} (${item.school_name})`
                                "
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon>
                                <v-icon size="23" color="#6B6B6B"
                                  >mdi-launch</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider
                            v-if="index < searchList.length - 1"
                            :key="index + 'div'"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card>
                  </v-expand-transition>
                </div>

                <v-btn
                  @click="toggleParentSignUpModal({ show: true })"
                  style="border-radius: 6px"
                  :class="$vuetify.breakpoint.smAndUp ? 'px-7 ml-3' : ''"
                  color="#FFBA00"
                  :height="$vuetify.breakpoint.smAndUp ? 55 : 45"
                  v-if="!is_completed"
                >
                  <!--   <span>{{ $t('signin') }}</span>-->
                  <span>Sign In</span>
                </v-btn>
              </v-col>
              <v-col
                v-else
                cols="12"
                sm="6"
                class="align-self-center text-center"
                :class="
                  $vuetify.breakpoint.smAndUp
                    ? 'py-5 pl-sm-16 pr-3'
                    : 'pl-0 py-0 pb-4'
                "
              >
                <v-btn
                  v-if="redirectToParentDashboardFlag"
                  light
                  text
                  x-small
                  class="switchStudentPage-btn py-6"
                  @click="goToDashboardHandler()"
                >
                  <!--                  <v-icon color="#FFFFFF">mdi-repeat</v-icon>-->
                  <span class="mx-2" style="color: #ffffff">
                    Go To Dashboard
                  </span>
                  <!--                  <v-icon color="#FFFFFF">mdi-chevron-down</v-icon>-->
                </v-btn>
                <v-btn
                  v-if="selectStudentModalFlag"
                  light
                  text
                  x-small
                  class="switchStudentPage-btn py-6"
                  @click="toggleSelectStudentModal({ show: true })"
                >
                  <v-icon color="#FFFFFF">mdi-repeat</v-icon>
                  <span class="mx-2" style="color: #ffffff">
                    Switch Student Page
                  </span>
                  <v-icon color="#FFFFFF">mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn
                  light
                  text
                  class="logout-btn"
                  @click="onClickLogoutHandler()"
                >
                  <span style="color: #ffffff"> LOGOUT </span>
                </v-btn>
              </v-col>
              <v-progress-linear
                :active="!campaign.campaign_detail"
                :indeterminate="!campaign.campaign_detail"
                absolute
                bottom
                color="#FFBA00"
              ></v-progress-linear>
            </v-row>
          </v-container>
        </v-app-bar>
      </v-col>
    </v-row>
    <div class="appBarBottomImg">
      <img
        max-width="100%"
        class="imageBarBottom"
        src="@/assets/publicDonationPage/publicDonation1.svg"
      />
    </div>
    <v-main style="margin-top: -120px">
      <div v-if="campaign.campaign_detail">
        <campaign-completed v-if="is_completed"></campaign-completed>
        <public-donation-page v-else></public-donation-page>
      </div>
    </v-main>
    <v-footer color="#2C1963" dark padless v-if="!is_completed">
      <v-card class="py-10" color="#2C1963" width="100%">
        <v-card-text class="px-0">
          <v-container>
            <v-row :class="[$vuetify.breakpoint.smAndUp ? 'pt-5' : '']">
              <v-col
                cols="12"
                sm="4"
                :class="[$vuetify.breakpoint.xsOnly ? 'py-0' : '']"
                style="overflow: hidden"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                    :class="[$vuetify.breakpoint.xsOnly ? 'py-4' : '']"
                    :style="
                      $vuetify.breakpoint.xsOnly ? 'overflow-y:hidden' : ''
                    "
                  >
                    <div class="HarlemFooterImageDiv">
                      <img
                        src="@/assets/publicDonationPage/wizardprimarylogo.svg"
                        alt=""
                        class="HarlemFooterImage"
                      />
                    </div>
                    <div class="footerText">
                      WizFit Challenge is a initiative by The World Famous
                      Harlem Wizards
                    </div>
                    <div class="hl" v-if="$vuetify.breakpoint.xsOnly"></div>
                  </v-col>
                  <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp">
                    <div class="vl"></div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4">
                <v-row justify="center">
                  <v-col cols="12" sm="8">
                    <div class="footerLinks mt-8">
                      <div
                        style="
                          font-weight: 700;
                          font-size: 22px;
                          font-family: Roboto Slab;
                          line-height: 30px;
                          letter-spacing: 1px;
                        "
                      >
                        Useful links
                      </div>
                      <div
                        style="border-bottom: 5px solid #ffba00; width: 70px"
                      ></div>
                      <br /><br />
                      <a
                        href="mailto:harlemwizards.com"
                        style="color: white; font-size: 21px"
                      >
                        About us
                      </a>

                      <!--   <div class="borderLinksContent spacing">FAQs</div>-->

                      <div class="borderLinksContent spacing">
                        Privacy policy
                      </div>

                      <div class="borderLinksContent spacing">
                        Terms & Conditions
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" class="px-0">
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="8"
                    :class="[$vuetify.breakpoint.xsOnly ? 'pl-6' : '']"
                  >
                    <div class="footerLinks mt-8">
                      <div
                        style="
                          font-weight: 700;
                          font-size: 22px;
                          font-family: Roboto Slab;
                          line-height: 30px;
                          letter-spacing: 1px;
                        "
                      >
                        Need Help
                      </div>
                      <div
                        style="border-bottom: 5px solid #ffba00; width: 70px"
                      ></div>
                      <br /><br />
                      <!--   <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/contactLogo.svg"
                          alt=""
                          class="contactImg"
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'max-width:30px;' : ''
                          "
                        />
                        <div
                          style="margin-top: auto; margin-bottom: auto"
                          class="borderLinksContent ml-6"
                        >
                          <a href="tel:(201)-271-3600" style="color: white"
                            >(201)-271-3600</a
                          >
                        </div>
                      </div>-->
                      <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/message.svg"
                          alt=""
                          class="contactImg"
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'max-width:30px;' : ''
                          "
                        />
                        <div
                          style="margin-top: auto; margin-bottom: auto"
                          class="borderLinksContent ml-6"
                        >
                          <a
                            href="mailto:support@harlemwizardsinabox.com"
                            style="color: white"
                          >
                            support@harlemwizardsinabox.com
                          </a>
                        </div>
                      </div>
                      <br />
                      <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/location.svg"
                          alt=""
                          class="contactImg"
                        />
                        <div
                          style="
                            margin-top: auto;
                            margin-bottom: auto;
                            display: flex;
                          "
                          class="borderLinksContent ml-6"
                        >
                          Harlem Wizards Entertainment Basketball 330 Fairfield
                          Rd.
                          <br v-if="$vuetify.breakpoint.xsOnly" />
                          Fairfield, NJ 07004
                          <!--
                        <a href="https://maps.app.goo.gl/eg8sHhDbKGeeuNLP7" style="color: white"
                          >330 Fairfield Rd. Suite 2Fairfield, NJ 07004</a
                        >-->
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card color="#180943" width="100%">
        <v-divider color="#FFBA00"></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.smAndUp">
                <div class="footerParagraph">
                  Copyright © 2021 Harlem Wizards
                </div>
              </v-col>
              <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
              <v-col
                cols="12"
                sm="4"
                :style="
                  $vuetify.breakpoint.smAndUp
                    ? 'display: flex; flex-direction: row-reverse'
                    : 'display:flex;align-items:center;'
                "
                :class="[$vuetify.breakpoint.xsOnly ? 'px-7 py-0' : '']"
              >
                <a href="https://twitter.com/HarlemWizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/twitter.svg"
                    alt=""
                  />
                </a>
                <a href="https://www.facebook.com/TheHarlemWizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/facebook.svg"
                    alt=""
                  />
                </a>
                <a href="https://tiktok.com/@harlemwizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/tiktok.svg"
                    alt=""
                  />
                </a>
                <a href="https://www.instagram.com/worldfamousharlemwizards/">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/instagram.svg"
                    alt=""
                  />
                </a>
                <a href="https://www.youtube.com/user/HarlemWizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/youtube.svg"
                    alt=""
                  />
                </a>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="text-center px-4 py-0"
                v-if="$vuetify.breakpoint.xsOnly"
              >
                <div class="footerParagraph">
                  Copyright © 2021 Harlem Wizards
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
    <make-donation-modal></make-donation-modal>
    <thank-you-modal></thank-you-modal>
    <share-fundraiser-modal v-if="campaignDetails"></share-fundraiser-modal>
    <parent-sign-up-modal></parent-sign-up-modal>
    <parent-verify-email-modal></parent-verify-email-modal>
    <reset-password-modal></reset-password-modal>
    <select-student-modal v-if="selectStudentModalFlag"></select-student-modal>
    <create-student-modal @openAgain="openStudentCreate"></create-student-modal>
    <!-- <one-time-video-modal
      style="margin-top: -5660px"
      v-if="this.$route.name == 'publicParentDashboard'"
    ></one-time-video-modal>-->
    <alert-message-modal
      v-if="
        this.$route.name == 'publicParentDashboard' &&
        !this.alertmessagepopupshow
      "
    ></alert-message-modal>
    <invite-via-mail></invite-via-mail>
    <invite-via-sms></invite-via-sms>
  </div>
</template>

<script>
import Axios from "@/api/BaseAxios";
import {
  API_PUBLIC_GET_CAMPAIGN_DETAILS,
  API_ADMIN_STUDENT_MANAGEMENT_GET,
  API_PUBLIC_MASTER_SEARCH,
  API_GET_STUDENT_META_DATA
} from "@/constants/APIUrls";
// import { ROUTER_URL } from "@/constants/urls";
import {
  SUB_DOMAIN,
  CAMPAIGN_ID,
  ID,
  CAMPAIGN_DETAIL,
  SEARCH,
} from "@/constants/APIKeys";
import { mapActions, mapGetters } from "vuex";
import Helper from "@/helper";
import _ from "lodash";
import { SCHOOL, STUDENT, TEACHER } from "@/constants/ModuleKeys";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "PublicDonationPageLayout",
  components: {
    CreateStudentModal: () =>
      import(
        "@/components/PublicDonationPages/CreateStudentModal/CreateStudentModal"
      ),
    SelectStudentModal: () =>
      import(
        "@/components/PublicDonationPages/SelectStudentModal/SelectStudentModal"
      ),
    ResetPasswordModal: () =>
      import(
        "@/components/PublicDonationPages/ResetPasswordModal/ResetPasswordModal"
      ),
    ParentVerifyEmailModal: () =>
      import(
        "@/components/PublicDonationPages/ParentVerifyEmailModal/ParentVerifyEmailModal.vue"
      ),
    ParentSignUpModal: () =>
      import(
        "@/components/PublicDonationPages/ParentSignUpModal/ParentSignUpModal.vue"
      ),
    ShareFundraiserModal: () =>
      import(
        "@/components/PublicDonationPages/ShareFundraiserModal/ShareFundraiserModal.vue"
      ),
    ThankYouModal: () =>
      import(
        "@/components/PublicDonationPages/ThankYouModal/ThankYouModal.vue"
      ),
    MakeDonationModal: () =>
      import(
        "@/components/PublicDonationPages/MakeDonationModal/MakeDonationModal"
      ),
    PublicDonationPage: () =>
      import("@/views/PublicDonationPage/PublicDonationPage.vue"),
    inviteViaMail: () =>
      import(
        "@/components/PublicDonationPages/InviteViaMailModal/InviteViaMailModal"
      ),
    inviteViaSms: () =>
      import(
        "@/components/PublicDonationPages/InviteViaMailModal/InviteViaSmsModal"
      ),
    /**  OneTimeVideoModal: () =>
      import(
        "@/components/PublicDonationPages/OneTimeVideoModal/OneTimeVideoModal"
      ),*/
    AlertMessageModal: () =>
      import(
        "@/components/PublicDonationPages/AlertMessageModal/AlertMessageModal"
      ),
    CampaignCompleted: () =>
      import("@/views/PublicDonationPage/CampaignCompleted/CampaignCompleted"),
  },
  data() {
    return {
      loading: true,
      searchQuery: "",
      searchList: [],
      searchLoading: false,
      videoflag: false,
      videowatched: localStorage.getItem("is_watched"),
      isvideo: false,
      count: 1,
      campaign_status: "",
      alertmessagepopupshow: "",
      lang: "en",
      languageList: [
        { name: "English", value: "en" },
        { name: "Spanish", value: "es" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    campaignDetails() {
      return this.campaign.campaign_detail;
    },
    isLoggedIn() {
      return _.isEmpty(this.userDetails) ? false : this.userDetails;
    },
    is_completed() {
      if (this.campaign_status === "Completed") {
        return true;
      } else {
        return false;
      }
    },
    selectStudentModalFlag() {
      let flag = false;
      if (
        !_.isEmpty(this.userDetails) &&
        this.$route.name === ROUTER_URL.publicParentDashboard.name
      )
        flag = true;
      return flag;
    },
    redirectToParentDashboardFlag() {
      let flag = false;
      if (
        !_.isEmpty(this.userDetails) &&
        this.$route.name === ROUTER_URL.publicDonationPages.name
      )
        flag = true;
      return flag;
    },
    studentID() {
      return this.$route.query[STUDENT];
    },
    routesName() {
      return this.$route.name;
    },
  },
  watch: {
    //Watcher for detecting change in SEARCH
    searchQuery: function () {
      this.searchLoading = true;
      this.debounceGetCampaignData();
    },
    // routesName: function () {
    //   location.reload();
    // },
    studentID: function () {
      location.reload();
    },
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      updateCampaign: "publicDonationPage/updateCampaign",
      toggleMakeDonationModal: "publicDonationPage/toggleMakeDonationModal",
      toggleThankYouModal: "publicDonationPage/toggleThankYouModal",
      toggleParentSignUpModal: "publicDonationPage/toggleParentSignUpModal",
      updateUserDetails: "publicDonationPage/updateUserDetails",
      toggleSelectStudentModal: "publicDonationPage/toggleSelectStudentModal",
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      // toggleOneTimeVideoModal: "publicDonationPage/toggleOneTimeVideoModal",
      toggleAlertMessageModal: "publicDonationPage/toggleAlertMessageModal",
    }),
    getCampaignDetails() {
      const _this = this;
      this.$i18n.locale = this.lang; // Set the locale if not already set
      let type = this.publicDonationPageType();
      const successHandler = (res) => {
      this.camapignId=res.data.campaign_detail.id;
        this.campaign_status = res.data.campaign_detail.campaign_status;
        let {
          campaign_assests_list,
          campaign_detail,
          school_list,
          district_detail,
          school_detail,
          teacher_detail,
          student_detail,
          is_payout_done,
          student_assests_list,
          teacher_assests_list,
        } = res.data;
        _this.updateCampaign({
          type,
          campaign_assests_list,
          campaign_detail,
          school_list,
          district_detail,
          school_detail,
          teacher_detail,
          student_detail,
          student_assests_list,
          teacher_assests_list,
          is_payout_done,
        });
        if (this.$route.query.donation_id && this.$route.query.payment_status) {
          this.toggleThankYouModal({ show: true });
        }
        if (
          this.isLoggedIn &&
          this.$route.name === ROUTER_URL.publicDonationPages.name
        ) {
          // _this.$router.push({
          //   name: ROUTER_URL.publicParentDashboard.name,
          // });
        }
        _this.loading = false;
      };
      const failureHandler = (res) => {
        _this.showToast({ message: "Campaign does not exist!", color: "e" });
        // setTimeout(function(){ window.location.replace("http://www.w3schools.com"); }, 3000);
        console.log("Failure: ", res);
      };
      let formData = {};
      formData[SUB_DOMAIN] = window.location.host.split(".")[0];
      formData["lang"] = this.lang;
      if (type) formData = { ...formData, ...type };
      Axios.request_GET(
        API_PUBLIC_GET_CAMPAIGN_DETAILS,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    getCampaignSearchList() {
      const _this = this;
      const successHandler = (res) => {
        // console.log("Search List: ", res.data);
        _this.searchList = res.data.search_list;
        _this.searchLoading = false;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
        _this.searchLoading = false;
      };
      let formData = {};
      formData[SEARCH] = this.searchQuery;
      formData[CAMPAIGN_ID] = this.campaign[CAMPAIGN_DETAIL][ID];
      Axios.request_GET(
        API_PUBLIC_MASTER_SEARCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
     getStudentMetaData() {
      this.loading = true;
      const successHandler = () => {
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
          this.loading = false;
      };
      let formData = {};
      formData["student_id"]=5546;
      Axios.request_GET(
        API_GET_STUDENT_META_DATA,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    debounceGetCampaignData: _.debounce(function () {
      this.getCampaignSearchList();
    }, 500),
    publicDonationPageType() {
      let obj = {};
      if (this.$route.query[SCHOOL]) {
        obj.type = SCHOOL;
        obj.school_id = this.$route.query[SCHOOL];
      } else if (this.$route.query[STUDENT]) {
        obj.type = STUDENT;
        obj.student_id = this.$route.query[STUDENT];
      } else if (this.$route.query[TEACHER]) {
        obj.type = TEACHER;
        obj.teacher_id = this.$route.query[TEACHER];
      } else obj = {};
      return _.isEmpty(obj) ? false : obj;
    },
    searchItemClickHandler(item) {
      // console.log({ item });
      // let subDomain = window.location.host.split('.')[0];
      let subDomain = "";
      let queryParam = `${item.user_type}=${item.id}`;
      // console.log("subDomain: ", subDomain, "queryParam: ", queryParam);
      Helper.openPublicDonationPage(subDomain, queryParam);
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    onClickLogoutHandler() {
      authToken.removeAccessToken();
      this.updateUserDetails({});
      this.$router.push({
        name: ROUTER_URL.publicDonationPages.name,
        query: { ...this.$route.query },
      });
    },
    goToDashboardHandler() {
      this.$router.push({
        name: ROUTER_URL.publicParentDashboard.name,
        query: { ...this.$route.query },
      });
    },
    openStudentCreate() {
      this.toggleCreateStudentModal({ show: true, actionType: "add" });
    },
    getStudentDetail() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        this.toggleCreateStudentModal({ show: false });
        console.log(data);
        self.studentFirstName = data.student_detail.student_first_name;
        self.studentLastName = data.student_detail.student_last_name;
        self.parentName = data.student_detail.parent_name;
        self.parentEmail = data.student_detail.parent_email;
        self.studentGoal = data.student_detail.student_goal;
        self.grade = data.student_detail.teacher;
        self.jerseySize = data.student_detail.jersey_size;
        // self.shortSize = data.student_detail.shorts_size;
        self.specialMessage = data.student_detail.student_special_message;
        this.campaign_type = data.student_detail.campaign_type;
        this.alertmessagepopupshow = data.student_detail.share_intimation;
        localStorage.setItem("parentemail", self.parentEmail);
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };

      let formData = {};
      formData["student_id"] = this.studentID;
      console.log(formData);

      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
  },
  mounted() {
    if (
      this.$route.name === ROUTER_URL.publicParentDashboard.name &&
      this.$route.query.token
    ) {
      try {
        // Save token in local storage
        localStorage.setItem("token", this.$route.query.token);
      } catch (e) {
        console.log(e);
      }
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }
    console.log(_.isEmpty(authToken.decodedToken()));
    if (!_.isEmpty(authToken.decodedToken())) {
      this.updateUserDetails({ user: authToken.decodedToken() });
      // console.log("this.isLoggedIn: ", this.isLoggedIn);
    } else if (
      this.$route.name === ROUTER_URL.publicParentDashboard.name &&
      _.isEmpty(authToken.decodedToken())
    ) {
      let query = Object.assign({}, this.$route.query);
      this.$router.push({
        name: ROUTER_URL.publicDonationPages.name,
        query,
      });
    }
    this.getCampaignDetails();
    console.log("userDetails", this.userDetails.last_updated);
    //alert(this.$route.name);
    // if(this.$route.name == 'publicParentDashboard') {
    //  setTimeout(() => {
    //   this.toggleAlertMessageModal({ show: true });
    //  }, 1000);
    // }

    if (this.studentID) {
      if (
        this.$route.name == "publicParentDashboard" &&
        !this.alertmessagepopupshow
      ) {
        this.getStudentDetail();
        setTimeout(() => {
          this.toggleAlertMessageModal({ show: true });
        }, 3000);
      }
    }
    //   location.reload();
  },
  created() {},
};
</script>

<style scoped>
* {
  font-family: Lato;
}
.appBarTitle {
  font-size: 22px;
  font-weight: 700;
  padding-top: 20px;
}
.v-btn >>> span {
  color: #2c1963;
  font-weight: 700;
  font-size: 17px;
  font-family: Roboto Slab;
}
.footerParagraph {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 108.5%;
  margin-top: 13px;
}
.footerIcon {
  margin-left: 30px;
}
.footerText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 161.5%;
  /* or 45px */
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 40px;
}
.vl {
  border-left: 2px solid #a691e4;
  height: 300px;
  margin-top: 20px;
}
.hl {
  border-top: 2px solid #a691e4;
  width: 100%;
  margin-top: 20px;
}
.footerLinks {
  color: white;
  font-family: Roboto Slab;
}
.borderLinksContent {
  font-size: 20px;
  font-weight: 500;
}
a.link {
  color: white;
}
.spacing {
  margin-top: 28px;
}
.infoColumn {
  display: flex;
}
.searchList-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.searchList-secondryText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #757575;
}
.student {
  background: rgba(0, 133, 255, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.teacher {
  background: rgba(56, 167, 55, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #38a737 !important;
  text-transform: capitalize;
}
.school {
  background: rgba(45, 45, 45, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.appBarBottomImg {
  margin-top: 110px;
  margin-bottom: 10px;
}
.HarlemFooterImage {
  width: 250px;
}
.switchStudentPage-btn {
  border: 0.5px dashed #ffffff;
  border-radius: 8px;
  background: #341d75;
}
.switchStudentPage-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 215%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.logout-btn span {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 161.5%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  * {
    /* overflow-x: hidden; */
  }
  .footerIcon {
    width: 30px;
    margin-left: 20px;
  }
  .HarlemFooterImage {
    width: 190px;
  }
  .footerText {
    margin-top: 10px;
    font-size: 15px;
  }
  .borderLinksContent {
    font-size: 15px;
  }
  .contactImg {
    min-width: 40px;
  }
  .appBarTitle {
    font-size: 18px;
    padding-top: 15px;
  }
  .v-btn >>> span {
    color: #2c1963;
    font-weight: 700;
    font-size: 14px;
    font-family: Roboto Slab;
  }
  .appBarBottomImg {
    margin-top: 200px;
    margin-bottom: -10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
@media (min-width: 1264px) {
    .container {
        max-width: 100%;
    }
}
</style>
